import {BrowserRouter, Route, Routes} from "react-router-dom";
import './App.css';
import Home from "./pages/home/home";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Home/>}/>
      </Routes>
    </BrowserRouter>
  );
}
